import { getEmailSignInMethod, getRecaptchaMode } from './common'

const config = {
  apiKey: 'AIzaSyA61HW9ay0379IdQFWZ2OtLDejMTXhxStw',
  authDomain: 'sunny-garden-30b60.firebaseapp.com',
  databaseURL: 'https://sunny-garden-30b60.firebaseio.com',
  projectId: 'sunny-garden-30b60',
  storageBucket: 'sunny-garden-30b60.appspot.com',
  messagingSenderId: '9603660477',
  appId: '1:9603660477:web:818cbdec55521c4d4ad5d4',
}

let app: firebase.app.App
let uiConfig = {}

export const initFirebase = (onAuthStateChanged: (user: firebase.User | null) => void) => {
  const getApp = import('firebase/app')
  const getAuth = import('firebase/auth')
  const getDb = import('firebase/database')
  const getFunctions = import('firebase/functions')

  Promise.all([getApp, getAuth, getDb, getFunctions]).then(([firebase]) => {
    if (!app) {
      app = firebase.initializeApp(config)
    }
    window.firebase = firebase

    app.auth().onAuthStateChanged(onAuthStateChanged)
    uiConfig = {
      signInFlow: 'popup',
      signInOptions: [
        {
          provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
          // Whether the display name should be displayed in Sign Up page.
          requireDisplayName: true,
          signInMethod: getEmailSignInMethod(),
        },
        {
          provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
          recaptchaParameters: {
            size: getRecaptchaMode(),
          },
          defaultCountry: 'VN',
        },
        {
          provider: firebase.auth.FacebookAuthProvider.PROVIDER_ID,
          scopes: ['public_profile'],
        },
        {
          provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
          // Required to enable this provider in One-Tap Sign-up.
          authMethod: 'https://accounts.google.com',
          // Required to enable ID token credentials for this provider.
          clientId: '9603660477-p8u0kufat4ve8n409267gpff3cv1ueee.apps.googleusercontent.com',
        },
        firebase.auth.TwitterAuthProvider.PROVIDER_ID,
        {
          provider: 'microsoft.com',
          providerName: 'Microsoft',
          buttonColor: '#2F2F2F',
          iconUrl:
            'https://docs.microsoft.com/en-us/azure/active-directory/develop/media/howto-add-branding-in-azure-ad-apps/ms-symbollockup_mssymbol_19.png',
          loginHintKey: 'login_hint',
        },
        {
          provider: 'yahoo.com',
          providerName: 'Yahoo',
          buttonColor: '#861490',
          iconUrl: 'https://firebasestorage.googleapis.com/v0/b/sunny-garden-30b60.appspot.com/o/yahoo.png?alt=media',
          loginHintKey: 'login_hint',
        },
        firebase.auth.GithubAuthProvider.PROVIDER_ID,
      ],
      callbacks: {
        // Avoid redirects after sign-in.
        signInSuccessWithAuthResult: () => false,
      },
    }
  })
}

export const getUIConfig = () => uiConfig

export const getAuth = () => app.auth()
export const getDb = () => app.database()
