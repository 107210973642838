import React, { useEffect, useState } from 'react'
import CartContext, { CartMeta } from './CartContext'

const CART_KEY = 'cart'

const CartProvider = ({ children }: any) => {
  const [items, setItems] = useState<CartMeta[]>([])
  const [totalItems, setTotalItems] = useState<number>(0)
  const [totalItemsFee, setTotalItemsFee] = useState<number>(0)

  const setMeta = (newItems: CartMeta[]) => {
    let itemsCount = 0
    let itemsFee = 0
    newItems.forEach(item => {
      itemsCount += item.qty
      itemsFee += (item.sellPrice * item.qty)
    })
    localStorage.setItem(CART_KEY, JSON.stringify(newItems))
    setTotalItems(itemsCount)
    setTotalItemsFee(itemsFee)
  }

  const removeItem = (id: string) => {
    const newItems = items.filter(item => item.id !== id)
    setMeta(newItems)
    setItems(newItems)
  }

  const addItem = (data: CartMeta) => {
    const newItems = [...items]
    const foundIndex = newItems.findIndex(item => item.id === data.id)
    if (foundIndex === -1) {
      newItems.push(data)
    } else {
      const currentItem = newItems[foundIndex]
      newItems[foundIndex] = { ...data, qty: currentItem.qty + data.qty }
    }
    setMeta(newItems)
    setItems(newItems)
  }

  const updateQty = (id: string, qty: number) => {
    const newItems = items.map(item => {
      if (item.id === id) {
        return { ...item, qty }
      }
      return { ...item }
    })
    setMeta(newItems)
    setItems(newItems)
  }

  const totalWeights = () => {
    return items.reduce((accumulator, currentValue) => accumulator + ((currentValue.weight || 1000) * currentValue.qty), 0)
  }

  const clearCart = () => {
    setTotalItems(0)
    setTotalItemsFee(0)
    setItems([])
    localStorage.removeItem(CART_KEY)
  }

  useEffect(() => {
    const savedCart = localStorage.getItem(CART_KEY)
    if (!savedCart) {
      return;
    }
    const cartMeta: CartMeta[] = JSON.parse(savedCart)
    setItems(cartMeta)
    setMeta(cartMeta)
  }, [])

  return (
    <CartContext.Provider
      value={{
        items,
        totalItems,
        totalItemsFee,
        removeItem,
        addItem,
        updateQty,
        totalWeights,
        clearCart
      }}
    >
      {children}
    </CartContext.Provider>
  )
}

export default CartProvider
