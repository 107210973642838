import firebase from 'firebase/app'
import React, { useEffect, useState } from 'react'
import { getAuth, initFirebase } from '../../firebase'
import AuthContext from './AuthContext'

const AuthProvider = ({ children }: any) => {
  const [user, setUser] = useState<firebase.User | null>(null)
  const [loading, setLoading] = useState(true)

  const getDisplayName = () => {
    if (user === null) {
      return 'unknown'
    }
    return user.displayName || user.email || user.phoneNumber || 'unknown'
  }

  useEffect(() => {
    initFirebase(_user => {
      setUser(_user)
      setLoading(false)
    })
  }, [])

  return (
    <AuthContext.Provider
      value={{
        loading,
        getDisplayName,
        user,
        signOut: () => getAuth().signOut(),
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
