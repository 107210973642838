import React from 'react';
import { IPromotions } from '../ProductDetailSide';

export interface CartMeta {
  title: string
  sellPrice: number
  marketPrice: number
  weight: number
  id: string
  uid: string
  currency: string
  type: string
  qty: number
  maxQty: number
  imgUrl: string
  promotions: IPromotions[]
}

interface ICartContextProps {
  items: CartMeta[]
  totalItems: number
  totalItemsFee: number
  removeItem: (id: string) => void
  addItem: (data: CartMeta) => void
  updateQty: (id: string, qty: number) => void
  totalWeights: () => number
  clearCart: () => void
}

const CartContext = React.createContext<ICartContextProps>({
  items: [],
  totalItems: 0,
  totalItemsFee: 0,
  removeItem: (id: string) => {
    return;
  },
  addItem: (data: CartMeta) => {
    return;
  },
  updateQty: (id: string, qty: number) => {
    return;
  },
  totalWeights: () => {
    return 0;
  },
  clearCart: () => {
    return;
  }
})

export default CartContext
