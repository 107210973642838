import React from 'react';

// tslint:disable-next-line:no-empty
const AuthContext = React.createContext({
  loading: true,
  user: null as firebase.User | null,
  getDisplayName: (): string => '',
  signOut: () => {
    return;
  },
})

export default AuthContext
